import { Component, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { zoomOutRight } from 'ng-animate';

import { AssistanceBaseComponent } from '../assistance-base/assistance-base.component';
import { AppStateService } from '../app-state.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-assistance-resolution',
  templateUrl: './assistance-resolution.component.html',
  styleUrls: ['../assistance-base/assistance-base.component.scss', './assistance-resolution.component.scss'],
  animations: [
    trigger('zoomOutRight', [transition(':leave', useAnimation(zoomOutRight))]),
  ]
})
export class AssistanceResolutionComponent extends AssistanceBaseComponent {
  time = new Date();
  isRequestFormVisible = false;
  isConfirmDaysLeftFormVisible = false;
  isConfirmPhoneVisible = false; 
  isConfirmPhoneSubmitted = false;
  isWaitingTimeVisible = false;
  reason: string;
  daysLeft: string;
  phone: string;
  selectedDuration: number;

  get isSubmitRequestDisabled(): boolean {
    return !this.reason || this.reason.length === 0 || this.isConfirmDaysLeftFormVisible;
  }

  get isConfirmDaysLeftDisabled(): boolean {
    return !this.daysLeft || this.daysLeft.length  === 0 || this.isConfirmPhoneVisible;
  }

  get isConfirmPhoneDisabled(): boolean {
    return !this.phone || !this.phone.length || this.isConfirmPhoneSubmitted;
  }

  constructor(private appState: AppStateService, private http: HttpClient) {
    super();
  }

  ngOnInit(){

   if(this.appState.currentCustomer){
     this.phone = this.appState.currentCustomer.incomingNumber;
   }
  }

  update(data) {
    super.update(data);

    if (this.data.days){
      this.daysLeft = this.data.days;
    }

    if (this.data.phone){
      this.phone = this.data.phone;
    }

    // the value in ngOnInit seems to be inaccurate so get the value from here
    if(this.appState.currentCustomer && (!this.phone || !this.phone.length)) {
      this.phone = this.appState.currentCustomer.incomingNumber;
    }

    if (this.data.text) {
      this.isConfirmPhoneVisible = true;
    }

    if(this.data.reason) {
      this.reason = this.data.reason;
    }
  }

  showRequestForm() {
    this.isRequestFormVisible = true;
  }

  submitRequestForm() {
    this.isConfirmDaysLeftFormVisible = true;
  }

  submitConfirmDaysLeftForm() {
    this.isWaitingTimeVisible = true;
  }

  selectDuration(duration: number) {
    this.selectedDuration = duration;
  }

  submitConfirmPhoneForm() {
    const CHAT_URL_TEXT = 'https://us-central1-kpmg-demos.cloudfunctions.net/sendText';

    this.isConfirmPhoneSubmitted = true;

    if (this.phone && this.phone.length) {

      setTimeout(() => {
        let message = `Thanks for enrolling with My Healthcare service.`;
        let url = `${CHAT_URL_TEXT}?to=${this.phone}&message=${encodeURIComponent(message)}`;
        this.http.get(url).subscribe(r => console.log(r), e => console.error(e));
      }, 10000);

      setTimeout(() => {
        let message = `Prescription exception approved.`;
        let url = `${CHAT_URL_TEXT}?to=${this.phone}&message=${encodeURIComponent(message)}`;
        this.http.get(url).subscribe(r => console.log(r), e => console.error(e));
      }, 30000);

      setTimeout(() => {
        let message = `Prescription exception denied.`;
        let url = `${CHAT_URL_TEXT}?to=${this.phone}&message=${encodeURIComponent(message)}`;
        this.http.get(url).subscribe(r => console.log(r), e => console.error(e));
      }, 30000);
    }
  }
}
