import { Component, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { zoomOutRight } from 'ng-animate';

import { AssistanceBaseComponent } from '../assistance-base/assistance-base.component';
import { AppStateService } from '../app-state.service';

@Component({
  selector: 'app-assistance-alert',
  templateUrl: './assistance-alert.component.html',
  styleUrls: ['../assistance-base/assistance-base.component.scss', './assistance-alert.component.scss'],
  animations: [
    trigger('zoomOutRight', [transition(':leave', useAnimation(zoomOutRight))]),
  ]
})
export class AssistanceAlertComponent extends AssistanceBaseComponent {
  time = new Date();

  constructor(protected appState: AppStateService) {
    super();
  }

  markResolved() {
    this.appState.requestWidget('NURSE_AVAILABILITY_CONFIRMED');
  }
}
