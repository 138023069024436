import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Customer } from '../app-state.service';
import { AngularFireDatabase } from 'angularfire2/database';

@Component({
    selector: 'app-incoming-connection-dialog',
    templateUrl: './incoming-connection-dialog.component.html',
    styleUrls: ['./incoming-connection-dialog.component.scss']
})
export class IncomingConnectionDialogComponent {
    protected customer: Customer;
    protected _closeCalled: boolean = false;

    public caller_id: string;

    constructor(
        public db: AngularFireDatabase,
        public dialogRef: MatDialogRef<IncomingConnectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {

        if (!data) {
            console.error(`The data member for the incoming dialog wasn't supplied.`);
        }
        if (!data.customer) {
            console.error(`The data member for the incoming dialog didn't have a customer.`);
        }

        this.customer = data.customer;

        this.db.object('connect_call_session').valueChanges().subscribe((update: any) => {
            console.log('Call session updated');
            console.log(update);

            if (update) {
                this.caller_id = update;
                this.customer.incomingNumber = this.caller_id;
            }
        });
    }

    public onReadyClicked(e: any): void {
        if (this._closeCalled) {
            return;
        }
        this._closeCalled = true;

        this.dialogRef.close(this.caller_id);
    }
}
