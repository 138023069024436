import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-assistance-base',
  templateUrl: './assistance-base.component.html',
  styleUrls: ['./assistance-base.component.scss']
})
export class AssistanceBaseComponent implements OnInit {

  private _d;
  @Input()
  public data: any;

  public type: string;

  constructor() { }

  public addAssistanceWidget: Function;

  public update(data) { this.data = data; }

  ngOnInit() {
  }

}
