import { SPECIALTIES_TYPES } from './specialist-types';
import { daysAgo } from './date-util';

const data: any[] = [
  {
    date: daysAgo(20),
    description: 'Physical',
    doctor: 'Oliver',
    location: 'Springfield',
  },
  {
    date: daysAgo(112),
    description: 'Scheduled visit',
    doctor: 'Oliver',
    location: 'Springfield',
  },
  {
    date: daysAgo(200),
    description: 'Scheduled visit - Nephrology',
    doctor: 'Smith',
    location: 'Springfield',
    specialist: SPECIALTIES_TYPES.NEPHROLOGY,
  },
  {
    date: daysAgo(380),
    description: 'Scheduled visit - Nephrology',
    doctor: 'Smith',
    location: 'Springfield',
    specialist: SPECIALTIES_TYPES.NEPHROLOGY,
  },
];

export default data;
