const NOW = new Date();

export function daysAgo(days) {
  const date = new Date(NOW);
  date.setDate(date.getDate() - days);
  return date;
}

export function daysFromNow(days) {
  const date = new Date(NOW);
  date.setDate(date.getDate() + days);
  return date;
}
