import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AssistanceBaseComponent } from '../assistance-base/assistance-base.component';
import { trigger, style, animate, transition, useAnimation } from '@angular/animations';
import { zoomOut, zoomOutUp, zoomOutRight } from 'ng-animate';
import ProviderAvailability from '../../data/provider-availability';
import { AppStateService } from '../app-state.service';

@Component({
  selector: 'app-assistance-appointment-confirmed',
  templateUrl: './assistance-appointment-confirmed.component.html',
  styleUrls: ['../assistance-base/assistance-base.component.scss', './assistance-appointment-confirmed.component.scss'],
  animations: [
    trigger('zoomOutRight', [transition(':leave', useAnimation(zoomOutRight))]),
  ]
})
export class AssistanceAppointmentConfirmedComponent extends AssistanceBaseComponent {

  // chat_url_call: string;
  chat_url_text: string;
  phone: string;
  agentNumber: string;
  time: any = new Date();
  followupSent: boolean = false;

  selectedDate: any = ProviderAvailability.find(p => p.provider === 'Jon Brown').nextAvailableDate;

  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  constructor(protected appState: AppStateService, private http: HttpClient) {
    super();
    // this.chat_url_call = 'https://us-central1-kpmg-demos.cloudfunctions.net/connectCall';
    this.chat_url_text = 'https://us-central1-kpmg-demos.cloudfunctions.net/sendText';
    // this.agentNumber = '2013657552'; original project.
    this.agentNumber = '2402338248';
  }

  dateLabel() {
    return `${this.months[this.selectedDate.getMonth()]} ${this.selectedDate.getDate()}, ${this.selectedDate.getFullYear()}`;
  }

  update(data) {
    super.update(data);

    if (!this.followupSent && this.appState.currentCustomer.incomingNumber) { // && (data.phone || this.phone)) {
      this.followupSent = true;

      // trigger follow-up call from agent after a few seconds
      // setTimeout(() => {
      //   const followUpUrl = `${this.chat_url_call}?userNumber=${this.phone}&agentNumber=${this.agentNumber}`;
      //   return this.http.get(followUpUrl)
      //     .subscribe(r => console.log(r), e => console.log(e));
      // }, 10000);

      this.phone = this.appState.currentCustomer.incomingNumber; // data.phone || this.phone;
      const message = 'This is a reminder that you have an in-home MyDevice pump check scheduled ' +
        `with Jon Brown on ${this.dateLabel()} at 2pm. Reply to this message if you need to reschedule.`;

      const url = `${this.chat_url_text}?to=${this.phone}&message=${message}`;
      return this.http.get(url)
        .subscribe(r => console.log(r), e => console.error(e));
    }
  }
}
