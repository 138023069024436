import { Component } from '@angular/core';
import { bindTestScriptActions } from './test-script/setup';
import { AppStateService } from './app-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
title = 'app';

  constructor(appState: AppStateService) {
    bindTestScriptActions(appState);
  }
}
