import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './dashboard/dashboard.component';
import {
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDividerModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatExpansionModule,
    MatSidenavModule
} from '@angular/material';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { CaseCardComponent } from './case-card/case-card.component';
import { MainHeaderComponent } from './main-header/main-header.component';
import { FormsModule } from '@angular/forms';
import { SentimentStreamComponent } from './sentiment-stream/sentiment-stream.component';
import { IncomingConnectionDialogComponent } from './incoming-connection-dialog/incoming-connection-dialog.component';

import { AssistanceBaseComponent } from './assistance-base/assistance-base.component';
import {
    AssistanceAccountOverviewComponent,
    AssistanceAccountConfirmComponent } from './assistance-account-overview/assistance-account-overview.component';
import {
    AssistanceNurseAvailabilityComponent,
    AssistanceNurseAvailabilityConfirmedComponent } from './assistance-nurse-availability/assistance-nurse-availability.component';
import { AssistanceAppointmentCardComponent } from './assistance-appointment-card/assistance-appointment-card.component';
import { AssistanceAppointmentConfirmedComponent } from './assistance-appointment-confirmed/assistance-appointment-confirmed.component';
import {
    AssistanceRecommendComponent,
    AssistanceRecommendScheduleVisitComponent,
    AssistanceRecommendWipeOffComponent} from './assistance-recommend/assistance-recommend.component';
import { AssistanceDeviceIssuesComponent } from './assistance-device-issues/assistance-device-issues.component';
import { AssistanceRecentActivityComponent } from './assistance-recent-activity/assistance-recent-activity.component';
import { AssistanceDeviceProfileComponent } from './assistance-device-profile/assistance-device-profile.component';
import { AssistanceAlertComponent } from './assistance-alert/assistance-alert.component';
import { AssistanceResolutionComponent } from './assistance-resolution/assistance-resolution.component';
import { AssistanceReportComponent } from './assistance-report/assistance-report.component';
import { AssistanceInteractionSummaryComponent } from './assistance-interaction-summary/assistance-interaction-summary.component';
import { AssistancePrescriptionVerificationComponent } from './assistance-prescription-verification/assistance-prescription-verification.component';

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        CaseCardComponent,
        MainHeaderComponent,
        SentimentStreamComponent,
        IncomingConnectionDialogComponent,
        AssistanceBaseComponent,
        AssistanceAccountOverviewComponent,
        AssistanceAccountConfirmComponent,
        AssistanceDeviceIssuesComponent,
        AssistanceDeviceProfileComponent,
        AssistanceRecentActivityComponent,
        AssistanceNurseAvailabilityComponent,
        AssistanceNurseAvailabilityConfirmedComponent,
        AssistanceAppointmentCardComponent,
        AssistanceAppointmentConfirmedComponent,
        AssistanceRecommendComponent,
        AssistanceRecommendScheduleVisitComponent,
        AssistanceRecommendWipeOffComponent,
        AssistanceAlertComponent,
        AssistanceResolutionComponent,
        AssistanceReportComponent,
        AssistanceInteractionSummaryComponent,
        AssistancePrescriptionVerificationComponent
    ],
    imports: [
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireDatabaseModule,
        FormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatGridListModule,
        MatCardModule,
        MatMenuModule,
        MatIconModule,
        MatChipsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDividerModule,
        MatTableModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatDialogModule,
        MatSelectModule,
        MatExpansionModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSidenavModule,
    ],
    entryComponents: [
        IncomingConnectionDialogComponent,
        AssistanceAccountOverviewComponent,
        AssistanceAccountConfirmComponent,
        AssistanceNurseAvailabilityComponent,
        AssistanceNurseAvailabilityConfirmedComponent,
        AssistanceAppointmentCardComponent,
        AssistanceAppointmentConfirmedComponent,
        AssistanceDeviceIssuesComponent,
        AssistanceDeviceProfileComponent,
        AssistanceRecentActivityComponent,
        AssistanceRecommendComponent,
        AssistanceRecommendScheduleVisitComponent,
        AssistanceRecommendWipeOffComponent,
        AssistanceAlertComponent,
        AssistanceResolutionComponent,
        AssistanceReportComponent,
        AssistanceInteractionSummaryComponent,
        AssistancePrescriptionVerificationComponent
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
