import { Component, OnInit, Input, ViewChild, ElementRef, NgZone } from '@angular/core';
import { AssistanceBaseComponent } from '../assistance-base/assistance-base.component';
import PatientData from '../../data/patient-info';
import { MatTableDataSource } from '@angular/material';
import { trigger, style, animate, transition, useAnimation } from '@angular/animations';
import { zoomOut, zoomOutUp, zoomOutRight } from 'ng-animate';

import { AVAILABLE_SPECIALTIES } from '../../data/specialist-types';
import { AppStateService } from '../app-state.service';

enum DISPLAY_MODE {
    FULL = 'full',
    SPECIALIST = 'specialist',
}

@Component({
    selector: 'app-assistance-account-overview',
    templateUrl: './assistance-account-overview.component.html',
    styleUrls: ['../assistance-base/assistance-base.component.scss', './assistance-account-overview.component.scss'],
    animations: [
        trigger('zoomOutRight', [transition(':leave', useAnimation(zoomOutRight))]),
    ]
})
export class AssistanceAccountOverviewComponent extends AssistanceBaseComponent {
    mode: DISPLAY_MODE = DISPLAY_MODE.FULL;
    displayedColumns = ['date', 'description', 'doctor', 'location'];
    dataSource = new MatTableDataSource(PatientData);
    time = new Date();
    isEditingAddress = false;
    allowEdit = false;
    address = '10 Evergreen Terrace, Gywnn Oak, MD 21207';

    // update(data) {
    //     super.update(data);
    //     // this.dataSource.filter = JSON.stringify(this.data);

    //     if (data.address) { // Check if the specialist is defined
    //         console.log(`Address: ${data.address}`);

    //         if (AVAILABLE_SPECIALTIES.indexOf(data.specialist) > -1) { // Check if the specialist is supported
    //             this.mode = DISPLAY_MODE.SPECIALIST;

    //             const relevant = PatientData.filter((a) => a.specialist === data.specialist);
    //             this.dataSource = new MatTableDataSource(relevant);
    //         }
    //     }
    // }

    constructor(protected appState: AppStateService) {
        super();
    }
}

export class AssistanceAccountConfirmComponent extends AssistanceAccountOverviewComponent {
    allowEdit = true;
    nextWidget: string;

    update(data) {
        super.update(data);

        if (data.formattedAddress) {
            if (!this.isEditingAddress && this.address !== data.formattedAddress) {
                this.isEditingAddress = true;

                // Only give focus if we are changing state to start editing and the address is different
                // from what is already displayed.

                // Give focus to input. Note that the input is in a `ngIf` and `ViewChild` will
                // provide an undefined instead of the actual element. This approach is hacky, but
                // easy.
                // this.ngZone.runOutsideAngular(() => {
                //     setTimeout(() => {
                //         document.getElementById('editAddress').focus();
                //     }, 100);
                // });
            }

            this.address = data.formattedAddress;
        }

        if (data.nextWidgetToDisplay) {
            this.nextWidget = data.nextWidgetToDisplay;
        }
    }

    showNextWidget() {
        this.appState.requestWidget(this.nextWidget);
    }

    confirmAddress() {
        this.isEditingAddress = false;
        this.showNextWidget();
    }
}
