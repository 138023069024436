import { Component, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { zoomOutRight, bounce } from 'ng-animate';

import { AssistanceBaseComponent } from '../assistance-base/assistance-base.component';
import { AppStateService } from '../app-state.service';

@Component({
  selector: 'app-assistance-device-profile',
  templateUrl: './assistance-device-profile.component.html',
  styleUrls: ['../assistance-base/assistance-base.component.scss', './assistance-device-profile.component.scss'],
  animations: [
    trigger('bounce', [transition(':enter', useAnimation(bounce))]),
    trigger('zoomOutRight', [transition(':leave', useAnimation(zoomOutRight))]),
  ]
})
export class AssistanceDeviceProfileComponent extends AssistanceBaseComponent implements OnInit {
  time = new Date();
  deviceLastUpdate = new Date();
  attemptingToConnect = true;

  constructor(protected appState: AppStateService) {
    super();
  }

  ngOnInit() {
    setTimeout(() => {
      this.attemptingToConnect = false;
    }, 8000);

    setTimeout(() => {
      this.appState.requestWidget('RECOMMEND_VISIT');
    }, 9500);
  }
}
