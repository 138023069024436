import { Component } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { zoomOutRight, slideInDown, fadeIn } from 'ng-animate';

import { AssistanceBaseComponent } from '../assistance-base/assistance-base.component';

const MSG = {
  WIPE_OFF: 'Suggest wiping off the area and reattaching. User reported higher physical activity, sweat could be the issue.',
  SCHEDULE_VISIT: 'Schedule in-home device check',
};

@Component({
  selector: 'app-assistance-recommend',
  templateUrl: './assistance-recommend.component.html',
  styleUrls: ['../assistance-base/assistance-base.component.scss', './assistance-recommend.component.scss'],
  animations: [
    // trigger('slideInDown', [transition(':enter', useAnimation(slideInDown, {
    //   // Set the duration to 5seconds and delay to 2seconds
    //   // params: { timing: 5, delay: 1 }
    // }))]),
    trigger('attention', [transition(':enter', useAnimation(fadeIn))]),
    trigger('zoomOutRight', [transition(':leave', useAnimation(zoomOutRight))]),
  ]
})
export class AssistanceRecommendComponent extends AssistanceBaseComponent {
  time = new Date();

  message;

  constructor() {
    super();
  }
}

export class AssistanceRecommendWipeOffComponent extends AssistanceRecommendComponent {
  message = MSG.WIPE_OFF;

  constructor() {
    super();
  }
}

export class AssistanceRecommendScheduleVisitComponent extends AssistanceRecommendComponent {
  message = MSG.SCHEDULE_VISIT;

  constructor() {
    super();
  }
}
