import { Component, ComponentFactoryResolver, ViewChild, OnInit, ViewContainerRef, ElementRef } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { HttpClient } from '@angular/common/http';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce, zoomInLeft, zoomOut, zoomIn, fadeInDown, tada, flipInY, flipOutY, fadeOutUp, fadeOutDown, zoomInRight } from 'ng-animate';

import { SentimentHelper } from '../sentiment-helper';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IncomingConnectionDialogComponent } from '../incoming-connection-dialog/incoming-connection-dialog.component';
import { AppStateService } from '../app-state.service';
import { MatTableDataSource } from '@angular/material';

import DeviceIssueData from '../../data/device-issues';

import {
    AssistanceAccountOverviewComponent,
    AssistanceAccountConfirmComponent } from '../assistance-account-overview/assistance-account-overview.component';
import {
    AssistanceNurseAvailabilityComponent,
    AssistanceNurseAvailabilityConfirmedComponent } from '../assistance-nurse-availability/assistance-nurse-availability.component';
import { AssistanceAppointmentCardComponent } from '../assistance-appointment-card/assistance-appointment-card.component';
import { AssistanceAppointmentConfirmedComponent } from '../assistance-appointment-confirmed/assistance-appointment-confirmed.component';
import { AssistanceDeviceIssuesComponent} from '../assistance-device-issues/assistance-device-issues.component';
import {
    AssistanceRecommendWipeOffComponent,
    AssistanceRecommendScheduleVisitComponent } from '../assistance-recommend/assistance-recommend.component';
import { AssistanceRecentActivityComponent } from '../assistance-recent-activity/assistance-recent-activity.component';
import { AssistanceDeviceProfileComponent } from '../assistance-device-profile/assistance-device-profile.component';
import { AssistanceAlertComponent } from '../assistance-alert/assistance-alert.component';
import { AssistanceResolutionComponent } from '../assistance-resolution/assistance-resolution.component';
import { AssistanceReportComponent } from '../assistance-report/assistance-report.component';
import { AssistanceInteractionSummaryComponent } from '../assistance-interaction-summary/assistance-interaction-summary.component';
import { AssistancePrescriptionVerificationComponent } from '../assistance-prescription-verification/assistance-prescription-verification.component';

const widgetNameToTypeMap = {
    ALERT: [AssistanceAlertComponent],
    ACCOUNT_OVERVIEW: [AssistanceAccountOverviewComponent],
    ACCOUNT_CONFIRM: [AssistanceAccountConfirmComponent],
    NURSE_AVAILABILITY: [AssistanceNurseAvailabilityComponent],
    NURSE_AVAILABILITY_CONFIRMED: [AssistanceNurseAvailabilityConfirmedComponent],
    APPOINTMENT_CARD: [AssistanceAppointmentCardComponent],
    APPOINTMENT_CONFIRMED: [AssistanceAppointmentConfirmedComponent],
    DEVICE_ISSUES: [AssistanceDeviceIssuesComponent],
    DEVICE_PROFILE: [AssistanceDeviceProfileComponent],
    RECENT_ACTIVITY: [AssistanceRecentActivityComponent],
    RESOLUTION: [AssistanceResolutionComponent],
    RECOMMEND_WIPEOFF: [AssistanceRecommendWipeOffComponent],
    RECOMMEND_VISIT: [AssistanceRecommendScheduleVisitComponent],
    PRESCRIPTION_REPORT: [AssistanceReportComponent],
    INTERACTION_SUMMARY: [AssistanceInteractionSummaryComponent],
    PRESCRIPTION_VERIFICATION: [AssistancePrescriptionVerificationComponent]
};

// 750 Pratt St
// Baltimore, MD 21202
const ADDRESS_DEFAULTS = {
    ADMIN_AREA: 'MD',
    CITY: 'Baltimore',
    ZIP_CODE: '21202',
};

const CHAT_URL_CALL = 'https://us-central1-kpmg-demos.cloudfunctions.net/connectCall';
const CHAT_URL_TEXT = 'https://us-central1-kpmg-demos.cloudfunctions.net/sendText';

const incomingMessageOptions = [
    // 'I am very happy with my service, thanks!',
    // 'Everything looks good here, hooray!',
    // 'Now is the time to determine the problem.',
    // 'Would you like some breakfast this morning?',
    // 'My apologies, I am just a bit flustered.',
    // 'My order was not shipped, where is it!?',
    // 'I have some questions about my new router',
    // 'Fool, that will never work!'
];

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    animations: [
        trigger('bounce', [transition('* => *', useAnimation(bounce))]),
        trigger('tada', [transition('* => *', useAnimation(tada))]),
        trigger('newMessageAnimation', [transition(':enter', useAnimation(fadeInDown, { params: {/*delay: 3,*/ timing: 0.3 } }))]),
        trigger('zoomIn', [transition(':enter', useAnimation(zoomIn))]),
        trigger('zoomOut', [transition(':leave', useAnimation(zoomOut))]),
        trigger('flipInY', [transition(':enter', useAnimation(flipInY))]),
        trigger('flipOutY', [transition(':leave', useAnimation(flipOutY))]),
        trigger('fadeOutUp', [transition(':leave', useAnimation(fadeOutUp))]),
        trigger('fadeOutDown', [transition(':leave', useAnimation(fadeOutDown))]),
        trigger('zoomInLeft', [transition(':enter', useAnimation(zoomInLeft))]),
        trigger('zoomInRight', [transition(':enter', useAnimation(zoomInRight))])
    ],
})
export class DashboardComponent implements OnInit {

    @ViewChild('assistanceHost', { read: ViewContainerRef })
    assistanceHost;

    @ViewChild('searchResults') searchResults: ElementRef;

    sessionId = Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);

    messageHistory = [];
    realtimeMessage = null;
    latestMessage = null;
    latestIntentName = '';
    dialogRef: MatDialogRef<any> = null;
    widgets = [];
    aggregatedParameters: any = {};
    agentSessionStarted: boolean = false;

    deviceIssueDisplayedColumns = ['date', 'issueDescription'];
    deviceIssueDataSource = new MatTableDataSource(DeviceIssueData);
    deviceLastUpdate = new Date();

    ObjectKeys = Object.keys; // expose Object.keys to be callable in the template
    sentimentString(message) {
        if (message.sentiment && message.sentiment.length) {
            return message.sentiment.map(s => s.tone_name).join(', ');
        }
    }

    constructor(public db: AngularFireDatabase,
        public http: HttpClient,
        public componentFactoryResolver: ComponentFactoryResolver,
        matDialog: MatDialog,
        protected appState: AppStateService) {

//         const customerPhonePrompt = 'Enter the Customer\'s phone number. Or have them customer call this number: 484-232-8808.';
//         const customerPhoneNumber = prompt(customerPhonePrompt, localStorage.getItem('customerNumber') || '');
//         localStorage.setItem('customerNumber', customerPhoneNumber || '');

//         if (customerPhoneNumber) {
//             const message =
// `You recently received an A33 message on your insulin pump. This could be a critical pump error. Here are some MyDevice resources for your support:\n
// Reply to this message with 1 for videos on troubleshooting\n
// Reply to this message with 2 for FAQs regarding the A33 message\n
// Call MyDevice Support at 484-240-1929 for the next available specialist`;

//             const url = `${CHAT_URL_TEXT}?to=${customerPhoneNumber}&message=${encodeURIComponent(message)}`;
//             this.http.get(url).subscribe(r => console.log(r), e => console.error(e));
//         }

        const phonePrompt = 'Enter YOUR (the agent) phone number to have your speech transcribed, or "NONE"';
        const phoneNumber = prompt(phonePrompt, localStorage.getItem('agentNumber') || 'NONE');
        localStorage.setItem('agentNumber', phoneNumber || 'NONE');

        window.setTimeout(() => {
            // Open the incoming dialog component, use the 'currentCustomer' from the app state.
            // This will get closed in response to the first transcript coming in
            this.dialogRef = matDialog.open(IncomingConnectionDialogComponent, {
                width: '640px',
                data: { customer: appState.currentCustomer }
            });

            this.dialogRef.afterClosed().subscribe((dialogObserver) => {

                const sessionKey = this.dialogRef.componentInstance.caller_id;

                // in case this session key was used before, clear out leftover intents / transcriptions
                this.db.object('session/' + sessionKey + '/transcription').set({});
                this.db.object('session/' + sessionKey + '/intent').set({});

                // call connect_call cloud function to connect agents phone with the agent-logger bot to get transcription of agents speech
                if (phoneNumber.toUpperCase().trim() !== 'NONE') {
                    this.http.post<any>(CHAT_URL_CALL, {
                        // userNumber: '6672184465', // agent-logger bot number
                        userNumber: '4062195125', // agent-logger bot number
                        agentNumber: phoneNumber,
                        agentMessage: 'Connecting you to a caller.'
                    }).subscribe((response) => {
                        console.log(response);
                    });
                }

                this.db.object('session/' + sessionKey + '/transcription').valueChanges()
                .subscribe((update: any) => {
                    console.table(update);
                    if (update) {
                        if (update.realtimeMessage && update.realtimeMessage.trim().length) {
                            this.realtimeMessage = update.realtimeMessage;
                        } else if (update.lastMessage && update.lastMessage.trim().length) {
                            this.processNewMessage(update);
                        }

                        if (!this.agentSessionStarted && update.role === 'agent') {
                            // clear out connect_call_session value since this session has started and the
                            // session key has been picked up by agent-logger
                            this.agentSessionStarted = true;
                            this.db.object('connect_call_session').remove();
                        }
                    }
                });

                this.db.object('session/' + sessionKey + '/intent').valueChanges()
                .subscribe((update: any) => {
                    if (update) {
                        if (update.role !== 'agent' && update.lastIntentName && update.lastIntentName.trim().length) {
                            console.log(update.lastIntentName);
                            this.processNewIntent(update);
                        }
                    }
                });

                // We can move forward with the call...
                this.appState.startCall();

            }, null /*no handling for errors*/, null /*no complete handler*/);
        }, 0);

        // create a backdoor that can be used to send input via text in the console by typing `say("the message")`
        (window as any).say = (function (message) {
            this.processNewMessage({ lastMessage: message, timestamp: Date.now(), role: 'caller' }, true);
        }).bind(this);

        (window as any).answer = (function (message) {
            this.processNewMessage({ lastMessage: message, timestamp: Date.now(), role: 'agent' }, true);
        }).bind(this);
    }

    ngOnInit() {
        this.aggregatedParameters = {};

        // support one widget requesting that another be opened, via appState request
        this.appState.widgetRequested.subscribe(widgetName => {
            this.addAssistanceWidget(widgetName);
        });

        // support updating a parameter from within a widget
        this.appState.paramUpdateRequested.subscribe(update => {
            this.aggregatedParameters[update.name] = update.value;
        });

        this.addAssistanceWidget('INTERACTION_SUMMARY');

        this.addAssistanceWidget('PRESCRIPTION_REPORT');

        // NOTE: _Not_ for testing. The script starts with a recognized caller.
        this.addAssistanceWidget('ACCOUNT_OVERVIEW');
        this.addAssistanceWidget('PRESCRIPTION_VERIFICATION');


        // this.addAssistanceWidget('DEVICE_ISSUES');
        // this.addAssistanceWidget('RECENT_ACTIVITY');
        // this.aggregatedParameters['troubleshoot'] = 'WIPE_OFF';
        // this.addAssistanceWidget('WARN_TROUBLESHOOT');
        // this.addAssistanceWidget('DEVICE_PROFILE');


        // this.aggregatedParameters.ProductType = "Cell Phone";
        // this.aggregatedParameters.Product = "Note 9";
        // this.aggregatedParameters.Intent = 'appointment_denied';
        // this.aggregatedParameters.specialist = 'brain surgeon';
        // this.aggregatedParameters.set_nurses = 'Frankenstien';
        // this.aggregatedParameters.reasons = 'HTH, F/U, peripheral edema';
        // this.aggregatedParameters.set_timeframe = 'evening';
        // this.aggregatedParameters.phone = '619-251-7038';
    }

    addAssistanceWidget(name) {
        console.log(`Adding widget: ${name}`);
        const widgetTypes = widgetNameToTypeMap[name];
        widgetTypes.forEach((widgetType) => {
            let instance = this.widgets.find(w => w.type === widgetType.name);
            if (!instance) {
                // create widget and add it

                // HACK for this demo, delay showing the next widget if prior is updating, to allow update to
                // happen while it's still visible, before getting pushed down
                // const delay = ['ACCOUNT_OVERVIEW'].indexOf(name) >= 0 ? 3000 : 0;
                // setTimeout(() => {

                    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(widgetType);
                    const componentRef = this.assistanceHost.createComponent(componentFactory, 0);
                    instance = componentRef.instance;
                    instance.type = widgetType.name;
                    instance.addAssistanceWidget = this.addAssistanceWidget.bind(this);

                    // pass in user text as part of parameters for widgets that want it,
                    // but don't actually add it to the parameters object so it doesn't get shown with the others
                    instance.update({ userSaid: (this.messageHistory[0] || {}).text,  ...this.aggregatedParameters });

                    this.widgets.push(instance);
                // }, delay);

            }
        });

        this.searchResults.nativeElement.scrollTo(0, 0);
    }

    processNewIntent(update) {
        if (update.lastIntentName.toUpperCase().trim() !== 'FALLBACK' && !update.lastIntentName.startsWith('_')) {
            this.latestIntentName = update.lastIntentName;
            this.latestMessage.intent = { name: update.lastIntentName };

            if (update.lastParameters && Object.keys(update.lastParameters).length) {
                const widgetParameter = update.lastParameters.WIDGET_TO_DISPLAY;

                // simplify and aggregate the list of parameters
                Object.keys(update.lastParameters).forEach(key => {
                    let value = update.lastParameters[key];

                    // for isList type parameters, value is kind of an array..
                    if (value.values_) {
                        value = value.values_.map(v => v.kind_).join(', ');
                    }

                    if (!key.startsWith('#') &&
                        key.toUpperCase() !== key &&
                        !(typeof value === 'string' && value.startsWith('#')) &&
                        (!key.includes('Opt') || // not optional OR optional and has a value
                            (value && value.length)
                        )) {
                        this.aggregatedParameters[key.replace('Opt', '')] = value;
                    } 
                    
                    else {
                        console.log(`Supressed parameter: ${key} = ${value}`);
                    }
                });


                if (update.lastParameters.address) {
                    this.aggregatedParameters.formattedAddress = this.processAddress(update.lastParameters.address);
                }

                if (widgetParameter) {
                    // Add the widget(s). If multiple are requested at the same time add a delay to
                    // allow the user to notice that multiple are trigged by one intent.
                    widgetParameter.split(',').forEach((w, i) => {
                        setTimeout(() => this.addAssistanceWidget(w.trim()), i * 1000);
                    });
                }
            }
        }

        this.widgets.forEach(w => { w.update({ userSaid: (this.messageHistory[0] || {}).text, ...this.aggregatedParameters }); });
    }

    processNewMessage(update, emulated = false) {
        this.realtimeMessage = null;
        const messageData: any = {
            text: update.lastMessage,
            role: update.role || 'caller',
            timestamp: update.timestamp,
            sentiment: null,
            intent: {}
        };

        this.messageHistory.unshift(messageData);

        this.latestMessage = messageData;

        // TODO: remove the dialogflow part of this since we're getting that info from firebase
        // TODO: Switch over to new non CDW agent
        this.http.post<any>('https://us-central1-kpmg-demos.cloudfunctions.net/chatMessageAnalyzer', {
            message: update.lastMessage,
            dialogflowProjectId: 'kpmg-demo-cvs-agent',
            sessionId: this.sessionId
        }).subscribe((response) => {
            console.log(response);

            // have to create new object instead of just setting the sentiment attribute
            // in order to trigger case-card's change detector
            this.latestMessage = Object.assign({},
                this.latestMessage, {
                    sentiment: response.tones,
                    sentimentSummary: SentimentHelper.summarize(response.tones)
                });
            this.messageHistory[0].sentimentSummary = this.latestMessage.sentimentSummary;
            // if (this.latestMessage.role === 'caller' && this.latestMessage.sentimentSummary.scoreValue <= -0.8) {
            //     this.addAssistanceWidget('DISCOUNT_FORM');
            // }

            if (emulated && messageData.role === 'caller' && response.intent) {
                // if we're emulating a voice message, take the intent recognition from this service response and process it
                // (normally we just use the intent recognition done by the speech-recognizer site and passed to us through firebase)
                const lastIntentName = response.intent.name;
                const lastParameters = this.processFields(response.intent.parameters.fields);

                this.processNewIntent({ lastIntentName, lastParameters });
            }
        });
    }

    // Turn Dialogflow fields into a simplified objects
    processFields(fields, state = {}): { [key: string]: string|object } {
        Object.keys(fields).map((k) => {
            if (fields[k].kind === 'stringValue') {
                state[k] = fields[k].stringValue;
            } else if (fields[k].kind === 'structValue') {
                state[k] = this.processFields(fields[k].structValue.fields);
            }
        });

        return state;
    }

    processAddress(address): string {
        // if (!address['admin-area']) { address['admin-area'] = ADDRESS_DEFAULTS.ADMIN_AREA; }
        // if (!address['city']) { address['city'] = ADDRESS_DEFAULTS.CITY; }
        // if (!address['zip-code']) { address['zip-code'] = ADDRESS_DEFAULTS.ZIP_CODE; }

        const formattedAddress = [
            address['street-address'],
            address.city || ADDRESS_DEFAULTS.CITY,
            address['admin-area'] || ADDRESS_DEFAULTS.ADMIN_AREA,
            address['zip-code'] || ADDRESS_DEFAULTS.ZIP_CODE,
        ].join(' ');

        return formattedAddress;
    }

    formatScore(score: number): string | number {
        if (score !== undefined && score !== null) {
            return Math.round(score * 100) / 100;
        }

        return '';
    }
}
