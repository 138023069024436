class SentimentSummary {
    imageName: string;
    labelText: string;
    scoreText: string;
    topSentiments: any[];
    scoreValue: number;
}

const ToneToMultiplierMap = {
    sad: -1,
    frustrated: -1,
    satisfied: 1,
    excited: 1,
    polite: 0.5,
    impolite: -0.75,
    sympathetic: 1,
    otherwise: 0
};

export const ToneToImageMap = {
    sad: 'sentiment_dissatisfied',
    frustrated: 'sentiment_very_dissatisfied',
    satisfied: 'sentiment_satisfied',
    excited: 'sentiment_very_satisfied',
    polite: 'polite',
    impolite: 'mood_bad',
    sympathetic: 'mood_sympathetic',
    otherwise: 'sentiment_neutral'
};

export class SentimentHelper {
    static summarize(sentiment: any[]): SentimentSummary {
        const result = new SentimentSummary();

        if (!sentiment || !sentiment.length) {
            result.imageName = ToneToImageMap.otherwise;
            result.labelText = 'Neutral';
            result.scoreText = '';
            result.topSentiments = [];
            result.scoreValue = 0;
            return result;
        }

        result.topSentiments = sentiment
            .sort((lhs, rhs) => rhs.score - lhs.score).slice(0, 2);

        result.labelText = result.topSentiments
            .map(i => i.tone_name).join(', ');
        result.scoreText = result.topSentiments
            .map(i => Math.round(i.score * 100) + '%').join(', ') + ' Confidence';

        // Now update the image name
        result.imageName = ToneToImageMap[result.topSentiments[0].tone_id] || ToneToImageMap.otherwise;

        result.scoreValue = 0;
        sentiment.forEach(s => {
            result.scoreValue += (ToneToMultiplierMap[s.tone_id] * s.score);
        });
        result.scoreValue = Math.min(1, Math.max(-1, result.scoreValue));

        return result;
    }
}
