import { daysAgo } from './date-util';

const data: any[] = [
  {
    date: daysAgo(1),
    issueDescription: 'Critical pump error',
    status: 'Unresolved',
    resolutionDate: 'In progress',
  },
  {
    date: daysAgo(17),
    issueDescription: 'Battery failed',
    status: 'Resolved',
    resolutionDate: daysAgo(16),
  },
  {
    date: daysAgo(37),
    issueDescription: 'Loading incomplete',
    status: 'Resolved',
    resolutionDate: daysAgo(36),
  },
  {
    date: daysAgo(97),
    issueDescription: 'No reservoir detected',
    status: 'Resolved',
    resolutionDate: daysAgo(96),
  },
];

export default data;
