import { Component } from '@angular/core';

import { AssistanceBaseComponent } from '../assistance-base/assistance-base.component';
import { AppStateService } from '../app-state.service';

@Component({
  selector: 'app-assistance-prescription-verification',
  templateUrl: './assistance-prescription-verification.component.html',
  styleUrls: ['../assistance-base/assistance-base.component.scss', './assistance-prescription-verification.component.scss']
})
export class AssistancePrescriptionVerificationComponent extends AssistanceBaseComponent {
  time = new Date();
  
  constructor(protected appState: AppStateService) {
    super();
  }

}
