import { Component, OnInit } from '@angular/core';
import { AppStateService } from '../app-state.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit {

  constructor(protected appState: AppStateService) { }

  ngOnInit() {
  }

}
