// A simple object for pausing the execution of the script until a specific intent is triggered by
// manual interaction or until the script is manually resumed.
export class Pause {
    constructor(public msg: string, public autoResumeWidget?: string) { }
}

// Adds a delay beyond the normal interval to allow longer timed sequences to complete.
export class Timeout {
    constructor(public dwellTimeMs: number, public msg?: string) { }
}

export interface IScriptStep {
    func: string; // The function to invoke. Either `say` or `answer`.
    msg: string; // The message / text / speech in provide.
}

export interface IScriptRunner {
    // Defined in "setup.ts"
    playTestScript(startIndex?: number);
    resumeTestScript();
    pauseTestScript();
    executeTestScriptStep(executeTestScriptStep: IScriptStep | Pause);

    // Defined on `window` in the dashboard component for scoping convenience.
    say(msg: string);    // Client says
    answer(msg: string); // Agent says
}
