import { Injectable, Output, EventEmitter } from '@angular/core';

export class Customer {
    public fullName: string;
    public greeting: string;
    public email: string;
    public companyName: string;
    public customerNumber: string;
    public incomingNumber: string;

    /**
     * Ranges from 0-5 in 0.5 increments
     */
    public statusLevel: number;
    public appointments: number;
    public memberId: string;
    public activeSince: Date;
}

const specialOffsetForRMNumbers = 40000;

@Injectable({
    providedIn: 'root'
})
export class AppStateService {

    @Output() widgetRequested: EventEmitter<string> = new EventEmitter();
    @Output() paramUpdateRequested: EventEmitter<any> = new EventEmitter();

    public showDeviceSidebar: boolean = true;

    constructor() { }

    /**
     * We could get this from session storage as well
     */
    protected _caseNumber: number = Math.floor(
        Math.random() * (1000000 - (specialOffsetForRMNumbers + 1)) + specialOffsetForRMNumbers
    );

    protected __callStates = {
        ACTIVE: 'active',
        COMPLETED: 'completed',
        UNINITIALIZED: ''
    };

    protected __customers: Array<Customer> = [
        {
            fullName: 'Karen Davies',
            greeting: 'Mr. Miller',
            email: 'jeff.miller.raa@kpmgdemos.com',
            companyName: 'Dynamics International',
            customerNumber: '313448-33',
            appointments: 12,
            statusLevel: 4.5,
            incomingNumber: '153-404-2932',
            memberId: '1192313',
            activeSince: new Date(2016, 1, 6),
        }
        // {
        //     fullName: 'Timothy Calderon',
        //     greeting: 'Mr. Calderon',
        //     email: 'tim.calderon.raa@kpmgdemos.com',
        //     companyName: 'Tang-Bella Companies',
        //     customerNumber: '879463-68',
        //     appointments: 14,
        //     statusLevel: 3.5,
        //     incomingNumber: '189-879-5618',
        //     memberId: '798313',
        //     activeSince: new Date(2012, 1, 15),
        // },
        // {
        //     fullName: 'Alice Mattheson',
        //     greeting: 'Ms. Mattheson',
        //     email: 'a.mattheson.raa@kpmgdemos.com',
        //     companyName: 'Portsmouth Ship and Trade',
        //     customerNumber: '653681-06',
        //     appointments: 22,
        //     statusLevel: 5.0,
        //     incomingNumber: '554-902-0756',
        //     memberId: '2313756',
        //     activeSince: new Date(2010, 1, 1),
        // },
        // {
        //     fullName: 'Sally Waterford',
        //     greeting: 'Mrs. Waterford',
        //     email: 'swaterford.raa@kpmgdemos.com',
        //     companyName: 'CCMRS, Inc',
        //     customerNumber: '468713-23',
        //     appointments: 6,
        //     statusLevel: 2.0,
        //     incomingNumber: '558-641-7946',
        //     memberId: '9782313',
        //     activeSince: new Date(2008, 9, 1),
        // },
        // {
        //     fullName: 'Charles Smith',
        //     greeting: 'Mr. Smith',
        //     email: 'chuck.smith.raa@kpmgdemos.com',
        //     companyName: 'Datana Technologies',
        //     customerNumber: '857132-94',
        //     appointments: 3,
        //     statusLevel: 1.5,
        //     incomingNumber: '545-356-2007',
        //     memberId: '8192313',
        //     activeSince: new Date(2012, 6, 19),
        // }
    ];

    // The state of the call, should be 'active' when the call is active.
    protected callState: String = this.__callStates.UNINITIALIZED;

    /**
     * A customer we will use for this session.
     */
    public _currentCustomer: Customer = this.__customers[Math.round(Math.random() * (this.__customers.length - 1))];

    /**
     * The number of milliseconds from epoch when the call was started.
     */
    protected callStarted: number = 0;

    /**
     * This tracks the single timeout we use to update the local property (So multiple callers dont have their own timeout)
     */
    protected callIntervalId: number = 0;

    /**
     * Internally updated string with time duration as hh:mm:ss.
     */
    public _callDuration: String = '--:--';

    protected pad(val: number): String {
        return (val > 9 ? '' : '0') + val.toString();
    }

    protected padZeros(val: number, size: number): String {
        let ret = val.toString();
        size = Math.max(1, Math.min(size, 10));
        while (ret.length < size) {
            ret = '0' + ret;
        }
        return ret;
    }

    public startCall() {
        if (this.callState === this.__callStates.ACTIVE) {
            console.warn('appState::startCall was called while a call was already active.');
            return;
        }
        this.callState = this.__callStates.ACTIVE;
        this.callStarted = Date.now();
        this.callIntervalId = window.setInterval(() => {
            let seconds = Math.round((Date.now() - this.callStarted) / 1000.0);
            let minutes = Math.floor(seconds / 60.0);
            const hours = Math.floor(minutes / 60.0);
            seconds = Math.floor(seconds - minutes * 60);
            minutes = Math.floor(minutes - hours * 60);
            // Add hours only when present
            this._callDuration = (hours > 0 ? this.pad(hours) + ':' : '') + this.pad(minutes) + ':' + this.pad(seconds);
            if (seconds % 30 === 0) {
                console.log(`Call duration is ${this._callDuration}.`);
            }
        }, 1000);
    }

    /**
     * Ends the call and related tracking.
     */
    public endCall() {
        if (this.callState !== this.__callStates.ACTIVE) {
            console.log(
                `appState::endCall was called while the call state was not ${
                this.__callStates.ACTIVE
                }. Call state was '${this.callState}'.`
            );
            return;
        }

        // Set state to being done
        this.callState = this.__callStates.COMPLETED;
        // Clean up the old time out.
        if (this.callIntervalId > 0) {
            window.clearInterval(this.callIntervalId);
            this.callIntervalId = 0;
        }
    }

    /**
     * Returns the call duration if the call is active...
     */
    public get callDuration(): String {
        if (this.callState === this.__callStates.ACTIVE) {
            if (this.callStarted > 0) {
                return this._callDuration;
            } else {
                console.warn(`CallState was started, but the callStarted value isn't set properly.`);
                return '__:__';
            }
        }

        console.log(`appState::callState is not ${this.__callStates.ACTIVE}, cannot return a duration.`);
        return '--:--';
    }

    /**
     * This will randomly change the customer based on the internal array of customers.
     */
    public selectNewRandomCustomer() {
        this._currentCustomer = this.__customers[Math.round(Math.random() * (this.__customers.length - 1))];
        return this._currentCustomer;
    }

    public selectNewRandomCaseNumber() {
        this._caseNumber = Math.floor(
            Math.random() * (1000000 - (specialOffsetForRMNumbers + 1)) + specialOffsetForRMNumbers
        );
        return this._caseNumber;
    }

    /**
     * Returns the current customer object.
     */
    public get currentCustomer(): Customer {
        return this._currentCustomer;
    }

    public get currentCaseNumber(): String {
        // TODO: This prefix could be parameterized
        return 'RM' + this.padZeros(this._caseNumber, 6);
    }

    public get currentCompanyName(): String {
        return this._currentCustomer.companyName;
    }

    public get currentCompanySite(): String {
        return 'RAA.com';
    }

    public requestWidget(widgetName) {
        this.widgetRequested.emit(widgetName);
    }

    public requestParamUpdate(name, value) {
        this.paramUpdateRequested.emit({ name: name, value: value });
    }
}
