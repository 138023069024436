import { Pause, Timeout, IScriptStep, IScriptRunner } from './types';
import { TEST_SCRIPT } from './script';
import { AppStateService } from '../app-state.service';
import { Subscription } from 'rxjs';
const STEP_INTERVAL = 5000;

// NOTE: This function is used as a closure for the state of script (i.e. `testScriptIndex` and
//       `testScriptIntervalId`).
export function bindTestScriptActions(appState: AppStateService): void {
  let testScriptIndex = 0; // Current item in the script.
  let testScriptTimeoutId = null; // The timeout ID so the script step can be cancelled.
  let dwellTimeoutId = null; // The timeout ID so the dwell timeout can be cancelled.
  let pendingIntent: Subscription = null;

  // Encourage linter to accept the functions that have been added to `window`
  const win = (window as any as IScriptRunner);

  // Removes the pending wiidget subscription for `Pause` objects that use automatic resume.
  function clearPendingWidget() {
    if (pendingIntent) {
      pendingIntent.unsubscribe();
      pendingIntent = null;
    }
  }

  function printTimeoutMessage(step: Timeout) {
    let msg = `
*** SCRIPT TIMEOUT: ${step.dwellTimeMs}ms ***
`;

    if (step.msg) {
      msg += `
MESSAGE:
    ${step.msg}

`;
    }

    console.log(msg);
  }

  function printPauseMessage(step: Pause) {
      console.log(`
*** PAUSING SCRIPT ***

MESSAGE:
    ${step.msg}

${ step.autoResumeWidget ? `Script will auto resume after ${step.autoResumeWidget} or m` : 'M' }annually resume using:
\`playTestScript()\` or \`resumeTestScript()\`.

`);
  }

  function executeNextTestScriptStep(): void {
    const step: IScriptStep | Pause | Timeout = TEST_SCRIPT[testScriptIndex];
    testScriptIndex++;

    if (step instanceof Timeout) {
      dwellTimeoutId = setTimeout(() => {
        dwellTimeoutId = null;
        console.log('*** TIMEOUT COMPLETE ***');
        win.resumeTestScript();
      }, step.dwellTimeMs);

      printTimeoutMessage(step);
    } else if (step instanceof Pause) {
      if (step.autoResumeWidget) {
        pendingIntent = appState.widgetRequested.subscribe((widgetName) => {
          if (widgetName === step.autoResumeWidget) {
            console.log(`*** WIDGET ${widgetName} INVOKED ***`);
            clearPendingWidget();
            win.resumeTestScript();
          }
        });
      }

      printPauseMessage(step);
    } else {
      win[step.func](step.msg);

      const peekStep = TEST_SCRIPT[testScriptIndex];

      if (peekStep instanceof Timeout || peekStep instanceof Pause) {
        // Immediately move on to Timeout and Pause steps.
        win.resumeTestScript();
      } else {
        // What a `STEP_INTERVAL` amout of time before executing next step.
        testScriptTimeoutId = setTimeout(() => win.resumeTestScript(), STEP_INTERVAL);
      }
    }
  }

  function pauseTestScript(): void {
    if (testScriptTimeoutId) {
      clearTimeout(testScriptTimeoutId);
      testScriptTimeoutId = null;
    }
  }

  // Start or resume a script.
  win.playTestScript = (startIndex?): void => {
    if (startIndex !== undefined && startIndex !== null) {
        testScriptIndex = startIndex;
    }

    // End of script
    if (testScriptIndex >= TEST_SCRIPT.length) {
      // Cancel listeners and reset to beginning.
      clearPendingWidget();
      pauseTestScript();
      testScriptIndex = 0;
    } else {
      // Don't leave uncleared auto-resume subscriptions active.
      if (pendingIntent) { clearPendingWidget(); }

      if (dwellTimeoutId) {
        clearTimeout(dwellTimeoutId);
        dwellTimeoutId = null;
      }

      executeNextTestScriptStep();
    }
  };

  // Easy alias for resuming test scripts.
  win.resumeTestScript = win.playTestScript;

  // Halt automatic execution.
  win.pauseTestScript = pauseTestScript;

  (window as any).help = (): void => {
    // tslint:disable:max-line-length
    console.log(`
HELP
Some useful commands for testing agent assist:

* \`playTestScript(index?)\` -- Plays the test script. An index can be passed to set a start point; otherwise uses previous index (resumes where it left off).

* \`resumeTestScript()\` -- An alias for \`playTestScript()\`

* \`pauseTestScript()\` -- Pauses the currently running test script.

* \`say(msg)\` -- Adds dialog from the client / customer perspective.

* \`answer(msg)\` -- Adds dialog from the agent / rep perspective.
    `);
  };
}
