import { Component, OnInit, Input, OnDestroy, OnChanges, ViewChild } from '@angular/core';
import { Subscription, Observable, Subject } from 'rxjs';
import { SentimentStreamComponent } from '../sentiment-stream/sentiment-stream.component';
import { ToneToImageMap, SentimentHelper } from '../sentiment-helper';
import { AppStateService } from '../app-state.service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'case-card',
    templateUrl: './case-card.component.html',
    styleUrls: ['./case-card.component.scss']
})
export class CaseCardComponent implements OnInit, OnDestroy {
    private _message: any;
    private messageHistory: Array<Object>;

    public sentimentImageName: String = ToneToImageMap.otherwise;
    public primarySentiments: Array<any>;
    public sentimentNames: String;
    public sentimentScores: String;

    @ViewChild(SentimentStreamComponent) stream: SentimentStreamComponent;

    /**
     * The time the call has been active as a string of hh:mm:ss
     */
    get callTimeString(): String {
        return '';
    }

    @Input()
    set message(value: any) {
        this._message = value;
        this.messageHistory.push(value);
        if (this.stream && value) {
            this.stream.scoreMessage(value);
        }

        if (value && value.sentimentSummary) {
            const summary = value.sentimentSummary;
            this.primarySentiments = summary.topSentiments;
            this.sentimentNames = summary.labelText;
            this.sentimentScores = summary.scoreText;
            this.sentimentImageName = summary.imageName;
        }
        console.log(`Message History length is now ${this.messageHistory.length}`);
    }

    get message(): any {
        return this._message;
    }

    constructor(protected appState: AppStateService) {
        this.messageHistory = [];
        this.message = { sentiment: [{ tone_id: 'default', tone_name: 'TBD', score: 0 }] };
    }

    /**
     * @param value The value that is being used to set 'message'.
     */
    public setMessage(value: Object) {
        console.log(`Pushing ${value} to the history.`);
        this.messageHistory.push(value);
    }

    ngOnInit() {}

    ngOnDestroy() {
        // Clean up anything
        if (this.messageHistory) {
            // TODO: Do we delete the array?
        }
    }

    toggleDeviceSidebar() {
        this.appState.showDeviceSidebar = !this.appState.showDeviceSidebar;
    }
}
