import { Component, OnInit, Input } from '@angular/core';
import { AssistanceBaseComponent } from '../assistance-base/assistance-base.component';
import { MatTableDataSource } from '@angular/material';
import { trigger, style, animate, transition, useAnimation } from '@angular/animations';
import { zoomOut, zoomOutUp, zoomOutRight } from 'ng-animate';

import ProviderAvailability from '../../data/provider-availability';
import { AppStateService } from '../app-state.service';

@Component({
  selector: 'app-assistance-appointment-card',
  templateUrl: './assistance-appointment-card.component.html',
  styleUrls: ['../assistance-base/assistance-base.component.scss', './assistance-appointment-card.component.scss'],
  animations: [
    trigger('zoomOutRight', [transition(':leave', useAnimation(zoomOutRight))]),
  ]
})
export class AssistanceAppointmentCardComponent extends AssistanceBaseComponent {

  selectedDate: any = ProviderAvailability.find(p => p.provider === 'Jon Brown').nextAvailableDate;
  time: any = new Date();

  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  // phone;
  address;
  assessment = 'Infusion set has come loose';
  // disabled: boolean = true;

  constructor(protected appState: AppStateService) {
    super();
  }

  dateLabel() {
    return `${this.months[this.selectedDate.getMonth()]} ${this.selectedDate.getDate()}, ${this.selectedDate.getFullYear()}`;
  }

  todayLabel() {
    const now = new Date();
    let hrs = now.getHours();
    let ampm = 'AM';
    if (hrs > 11) {
      ampm = 'PM';
    }
    if (hrs > 12) {
      hrs = hrs - 12;
    }
    return `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}   ${hrs}:${now.getMinutes()}${ampm}`;
  }

  update(data) {
    super.update(data);
    // this.dataSource.filter = JSON.stringify(this.data);

    if (data.reasons) {
      this.assessment = data.reasons;
    }

    if (data.formattedAddress) {
      this.address = data.formattedAddress;
    }
  }

  schedule() {
    this.appState.requestWidget('APPOINTMENT_CONFIRMED');
  }

}
