import { Component, OnInit, Input } from '@angular/core';
import { AssistanceBaseComponent } from '../assistance-base/assistance-base.component';
import { MatTableDataSource } from '@angular/material';
import { trigger, style, animate, transition, useAnimation } from '@angular/animations';
import { zoomOut, zoomOutUp, zoomOutRight } from 'ng-animate';

import { cloneDeep, map, some } from 'lodash-es';

import ProviderAvailability from '../../data/provider-availability';
import { debug } from 'util';
import { daysFromNow } from 'src/data/date-util';
import { AppStateService } from '../app-state.service';

const DAY_NAMES = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];

// enum Timeframe {
//   Any = 'any',
//   Morning = 'morning',
//   Afternoon = 'afternoon',
//   Evening = 'evening',
// }

@Component({
  selector: 'app-assistance-nurse-availability',
  templateUrl: './assistance-nurse-availability.component.html',
  styleUrls: ['../assistance-base/assistance-base.component.scss', './assistance-nurse-availability.component.scss'],
  animations: [
    trigger('zoomOutRight', [transition(':leave', useAnimation(zoomOutRight))]),
  ]
})
export class AssistanceNurseAvailabilityComponent extends AssistanceBaseComponent implements OnInit {
  dayColumns = [0, 1, 2, 3, 4, 5, 6];
  displayedColumns = ['provider', 'day0', 'day1', 'day2', 'day3', 'day4', 'day5', 'day6'];
  dataSource = new MatTableDataSource(ProviderAvailability);
  displayData = ProviderAvailability;
  providers: Array<string> = [];
  currentTimeframe: string; // Timeframe;
  currentProvider: string;
  time: any = new Date();
  allowSelect = false;
  timeSelected = false;

  constructor(private appState: AppStateService) {
    super();
  }

  ngOnInit() {
    this.displayData = ProviderAvailability;
    this.providers = ProviderAvailability.map(p => p.provider.replace('Dr. ', ''));

    this.currentProvider = ''; // 'Daniel Smith';
    this.currentTimeframe = ''; // Timeframe.Any;
    this.applyFilters();
  }

  daysAway(date: Date) {
    const now = new Date();

    // TODO: Compare now to date and calculate days between.

    return 0;
  }

  // filterTime(timeframe: Timeframe) {
  //   let timeFilter: ({value: number}) => boolean;

  //   switch (timeframe) {
  //   case Timeframe.Morning:
  //     timeFilter = (t) => t.value < 12;
  //     break;
  //   case Timeframe.Afternoon:
  //     timeFilter = (t) => t.value >= 12 && t.value < 17;
  //     break;
  //   case Timeframe.Evening:
  //     timeFilter = (t) => t.value >= 17;
  //     break;
  //   case Timeframe.Any:
  //   default:
  //     timeFilter = (d) => true;
  //   }

  //   this.displayData = this.displayData
  //     .filter((d) => some(d.availableTimes, timeFilter))
  //     .map((d) => {
  //       d.availableTimes = d.availableTimes.filter(timeFilter);

  //       return d;
  //     });
  // }

  filterProvider(provider: string) {
    if (provider && provider !== 'all') {
      this.displayData = this.displayData.filter((r) => r.provider.indexOf(provider) >= 0);
    }
  }

  applyFilters() {
    this.displayData = cloneDeep(ProviderAvailability);

    // sort by date
    // this.displayData = this.displayData.sort((a, b) => {
    //   const aticks = a.nextAvailableDate.getTime();
    //   const bticks = b.nextAvailableDate.getTime();

    //   return aticks < bticks ? -1 : bticks < aticks ? 1 : 0;
    // });

    this.filterProvider(this.currentProvider);
    // this.filterTime(this.currentTimeframe);

    this.dataSource = new MatTableDataSource(this.displayData);
  }

  update(data) {
    super.update(data);
    // this.dataSource.filter = JSON.stringify(this.data);

    // if (data.timeframe) {
    //   this.currentTimeframe = data.timeframe;
    //   this.applyFilters();
    // }

    if (data.doctors) {
      this.currentProvider = data.doctors;
      this.applyFilters();
    }
  }

  selectTime(practioner, time) {
    console.log(practioner, time);
    this.currentProvider = practioner.provider;
    this.currentTimeframe = time.display;

    this.timeSelected = true;
  }

  getColumnDayName(dayOffset: number) {
    return DAY_NAMES[daysFromNow(dayOffset).getDay()];
  }

  getColumnDayNumber(dayOffset: number) {
    return daysFromNow(dayOffset).getDate();
  }

  next() {
    this.appState.requestWidget('APPOINTMENT_CARD');
  }
}

export class AssistanceNurseAvailabilityConfirmedComponent extends AssistanceNurseAvailabilityComponent {
  allowSelect = true;
}
