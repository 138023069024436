import { daysFromNow } from './date-util';

const data: any[] = [
  {
    daysOffset: 7,
    nextAvailableDate: daysFromNow(7),
    availableTimes: [ // For the next week
      [], // Day 0
      [{ display: '3:00 PM', value: 15 }, { display: '4:00 AM', value: 16 }], // 1
      [{ display: '5:00 PM', value: 17 }], // 2
      [], // 3
      [], // 4
      [{ display: '8:30 AM', value: 8.5 }, { display: '9:00 AM', value: 9 }, { display: '10:30 AM', value: 10.5 }], // 5
      [], // 6
    ],
    provider: 'Daniel Smith',
    location: 'Baltimore',
  },
  {
    daysOffset: 7,
    nextAvailableDate: daysFromNow(7),
    availableTimes: [ // For the next week
      [], // Day 0
      [], // 1
      [], // 2
      [], // 3
      [], // 4
      [], // 5
      [], // 6
    ],
    provider: 'Lily Jones',
    location: 'Baltimore',
  },
  {
    daysOffset: 7,
    nextAvailableDate: daysFromNow(7),
    availableTimes: [ // For the next week
      [], // Day 0
      [], // 1
      [], // 2
      [], // 3
      [], // 4
      [], // 5
      [], // 6
    ],
    provider: 'Fred Ortega',
    location: 'Baltimore',
  },
  {
    daysOffset: 2,
    nextAvailableDate: daysFromNow(2),
    availableTimes: [ // For the next week
      [], // Day 0
      [], // 1
      [{ display: '2:00 PM', value: 14 }], // 2
      [], // 3
      [], // 4
      [], // 5
      [], // 6
    ],
    provider: 'Jon Brown',
    location: 'Baltimore',
  },
];

export default data;
