import { Component, OnInit, Input } from '@angular/core';
import { AssistanceBaseComponent } from '../assistance-base/assistance-base.component';
import DeviceIssueData from '../../data/device-issues';
import { MatTableDataSource } from '@angular/material';
import { trigger, style, animate, transition, useAnimation } from '@angular/animations';
import { zoomOut, zoomOutUp, zoomOutRight } from 'ng-animate';

// import { AVAILABLE_SPECIALTIES } from '../../data/specialist-types';
import { AppStateService } from '../app-state.service';

enum DISPLAY_MODE {
    FULL = 'full',
    SPECIALIST = 'specialist',
}

@Component({
    selector: 'app-assistance-device-issues',
    templateUrl: './assistance-device-issues.component.html',
    styleUrls: ['../assistance-base/assistance-base.component.scss', './assistance-device-issues.component.scss'],
    animations: [
        trigger('zoomOutRight', [transition(':leave', useAnimation(zoomOutRight))]),
    ]
})
export class AssistanceDeviceIssuesComponent extends AssistanceBaseComponent {
    mode: DISPLAY_MODE = DISPLAY_MODE.FULL;
    deviceIssueDisplayedColumns = ['date', 'issueDescription', 'status', 'resolutionDate'];
    deviceIssueDataSource = new MatTableDataSource(DeviceIssueData);
    time = new Date();

    update(data) {
        super.update(data);
        // this.dataSource.filter = JSON.stringify(this.data);

        if (data.specialist) { // Check if the specialist is defined
            // if (AVAILABLE_SPECIALTIES.indexOf(data.specialist) > -1) { // Check if the specialist is supported
            //     this.mode = DISPLAY_MODE.SPECIALIST;

            //     const relevant = PatientData.filter((a) => a.specialist === data.specialist);
            //     this.dataSource = new MatTableDataSource(relevant);
            // }
        }
    }

    constructor(protected appState: AppStateService) {
        super();
    }

    isDate(arg: Date | string) {
        return arg instanceof Date;
    }
}
