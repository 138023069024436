import { Pause, IScriptStep, Timeout } from './types';

function client(msg): IScriptStep {
  return { func: 'say', msg };
}

function agent(msg): IScriptStep {
  return { func: 'answer', msg };
}

// Test script
export const TEST_SCRIPT: Array<IScriptStep | Pause | Timeout> = [
  agent('Hi Karen, I see you had an issue getting your prescription for Shazam renewed.'),
  client('Yes you are correct.'),
  agent('I see that you’ve just received a notification regarding your insulin pump. Is that ' +
    'what you are calling about?'),
  client('Yes, it said A33'),
  agent('Ok. This could be an issue with the sensor. Have you made any changes recently?'),
  client('I’ve been working out more.'),
  agent('That’s great, good for you. While I have you, why don’t you try to dry off the pump site.' +
    ' I’ll wait on the line.'),
  client('Ok. Wiping it off now.'),

  new Timeout(13000, 'Waiting for connection attempt and troubleshooting'),

  agent('That didn’t fix the problem. We should schedule an in-home device check with a nurse ' +
    'practitioner to get your pump back online.'),
  client('Ok, let\'s schedule it'),
  agent('Do you still live at 10 evergreen terrace?'),
  client('I’ve moved to 750 Pratt Street'),

  new Pause('Update and Confirm the caller\'s address', 'NURSE_AVAILABILITY'),

  agent('Ok. The nearest nurse is available to visit you tomorrow at 3pm. Does that work?'),
  client('I have to pick up my kids from soccer practice. Can we do the day after?'),
  agent('I can, but do you have a backup method for receiving insulin?'),
  client('I can do injections.'),

  new Pause('Mark the alert as resolved', 'NURSE_AVAILABILITY_CONFIRMED'),

  agent('Ok, how about 2 days from now at 2pm?'),
  client('Sounds good.'),

  new Pause('Finish scheduling the appointment', 'APPOINTMENT_CONFIRMED'),

  agent('Great. I’m here if you have any questions in the meantime. Have a great day.'),
  client('Good bye.'),
];
