import { Component, OnInit, Input } from '@angular/core';
import * as d3 from 'd3';
import * as d3Shape from 'd3-shape';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'sentiment-stream',
    templateUrl: './sentiment-stream.component.html',
    styleUrls: ['./sentiment-stream.component.scss']
})
export class SentimentStreamComponent implements OnInit {
    private x: Function;
    private y: Function;
    private line: d3Shape.Line<[number, number]>;
    private g;
    private data;
    private animating = false;
    private moodQueue = [];
    private animationDelay = 1500;
    private tooltip;
    public latestScore: number = 0;
    private latestRawScore: number = 0;
    private scoreText: String = '';
    private scoreImage: String = '';
    private role: String = '';
    private latestMood: any = {
        'role': '',
        'caller': {
            'score': 0,
            'accuracy': '',
            'image': '',
            'role': 'caller',
            'moodChange': false
        },
        'agent': {
            'score': 0,
            'accuracy': '',
            'image': '',
            'role': 'agent',
            'moodChange': false
        }
    };

    scoreMessage(message) {
        if (message.sentimentSummary) {

            this.scoreText = message.sentimentSummary.scoreText;
            this.latestScore = message.sentimentSummary.scoreValue;
            this.latestRawScore = message.sentimentSummary.scoreValue;
            this.scoreImage = message.sentimentSummary.imageName;
            this.role = message.role;

            if (this.latestScore === 0) {
                this.scoreText = 'Neutral statement';
            } else {
                this.scoreText = Math.trunc(this.latestScore * 100) + '%';
            }

            this.latestMood.role = message.role;
            this.latestMood[message.role] = {
                'score': this.latestScore,
                'accuracy': this.scoreText,
                'image': this.scoreImage,
                'role': this.role,
                'moodChange': true
            };

        }
    }



    pushMood(newMood) {
        if (!this.animating) {
            // this.updateMood(newMood);
        } else {
            this.moodQueue.push(newMood);
        }
    }



    animationFinished() {
        this.animating = false;
        if (this.moodQueue.length > 0) {
            // this.updateMood(this.moodQueue.pop());
        }

    }



    ngOnInit() {
        const that = this;
        const container = d3.select('.sentiment-container');
        const svg = d3.select('svg');

        // TODO: On resize we'll want to change this so it doesn't overflow the page
        const width = container.property('clientWidth');
        const height = container.property('clientHeight');
        svg.attr('width', width);
        svg.attr('height', height);
        // Number of Nodes that can fit on the chart
        const n = 30;

        const dataCaller = d3.range(n).map(() => {
            return {
                'score': this.latestScore,
                'accuracy': '0% Confidece',
                'hidden': true,
                'image': '',
                'role': 'caller'
            };
        });

        const dataAgent = d3.range(n).map(() => {
            return {
                'score': this.latestScore,
                'accuracy': '0% Confidece',
                'hidden': true,
                'image': '',
                'role': 'agent'
            };
        });

        const data = {
            'caller': dataCaller,
            'agent': dataAgent
        };

        this.data = data;
        const g = svg.append('g').attr('transform', 'translate(0,0)');
        this.g = g;

        const x = d3.scaleLinear()
            .domain([1, n - 2])
            .range([0, width]);

        const y = d3.scaleLinear()
            .domain([-1.5, 1.5])
            .range([height, 0]);


        const xAxis = d3.axisBottom(x).ticks(n).tickFormat('');
        const yAxis = d3.axisLeft(y).ticks(3).tickFormat('');
        this.x = x;
        this.y = y;

        const line = d3.line()
            .curve(d3.curveCardinal)
            .x(function (d, i) { return x(i); })
            .y(function (d, i) {
                return y(d.score);
            });
        this.line = line;

        // Medium Line
        g.append('line')
            .style('stroke', '#e8cbcc')
            .attr('x1', 10)
            .attr('y1', height / 2)
            .attr('x2', width)
            .attr('y2', height / 2);
        g.append('defs').append('clipPath')
            .attr('id', 'clip')
            .append('rect')
            .attr('transform', 'translate(10,0)')
            .attr('width', width - 30) // The 30 hides the next node
            .attr('height', height);

        g.append('g')
            .attr('clip-path', 'url(#clip)')
            .append('path')
            .datum(data.agent)
            .attr('class', 'line agent')
            .transition()
            .duration(1500)
            .ease(d3.easeLinear)
            .on('start', tickAgent);

        g.append('g')
            .attr('clip-path', 'url(#clip)')
            .append('path')
            .datum(data.caller)
            .attr('class', 'line caller')
            .transition()
            .duration(1500)
            .ease(d3.easeLinear)
            .on('start', tickCaller);


        // g.append('g')
        //     .attr('class', 'axis')
        //     .attr('transform', 'translate(' + (10) + ',0)')
        //     .call(yAxis);

        g.append('g')
            .attr('class', 'axis')
            .attr('transform', 'translate(10,' + (height - 10) + ')')
            .call(xAxis);

        // Nodes
        const node = g.append('g')
            .attr('clip-path', 'url(#clip)')
            .attr('class', 'node-path caller')
            .selectAll('.dot')
            .data(data.caller)
            .enter().append('circle')
            .attr('class', 'dot')
            .attr('cx', function (d, i) { return x(i); })
            .attr('cy', function (d) {
                return y(d.score);
            })
            .attr('r', 2);

        const node2 = g.append('g')
            .attr('clip-path', 'url(#clip)')
            .attr('class', 'node-path agent')
            .selectAll('.dot')
            .data(data.agent)
            .enter().append('circle')
            .attr('class', 'dot')
            .attr('cx', function (d, i) { return x(i); })
            .attr('cy', function (d) {
                return y(d.score);
            })
            .attr('r', 5);


        function tick(key: string) {

            const filter = '.' + key;

            // Push a new data point onto the back.
            data[key].shift();
            data[key].push({
                'score': that.latestMood[key].score,
                'accuracy': that.latestMood[key].accuracy,
                'hidden': that.latestMood.role !== key,
                'image': that.latestMood[key].image,
                'role': that.latestMood[key].role
            });

            // slight hack, otherwise whichever set runs first is immediately erase.
            setTimeout(() => { that.latestMood.role = ''; }, 200);

            // Redraw The Nodes
            const tickNode = g.selectAll('.node-path').filter(filter).selectAll('.dot')
                .data(data[key])
                .enter().append('g')
                .attr('class', 'dot')
                .attr('visibility', function (d) {

                    if (d.hidden) {
                        return 'hidden';
                    } else {
                        return 'shown';
                    }
                });

            // Position nodes
            tickNode.append('circle')
                .attr('cx', function (d, i) { return x(i); })
                .attr('cy', function (d) { return y(d.score); })
                .attr('r', 5);

            tickNode.append('image')
                .attr('xlink:href', function (d) {
                    if (d.image) {
                        return './assets/emotionalcons/small/' + d.image + '.png';
                    } else {
                        return '';
                    }

                })
                .attr('x', function (d, i) { return (x(i) - 10.5); })
                .attr('y', function (d) {
                    let modifier;
                    if (d.score < 0) {
                        modifier = -26;
                    } else {
                        modifier = 6;
                    }
                    return (y(d.score) + modifier);
                })
                .attr('width', '14px')
                .attr('height', '14px');

            // Redraw the line.
            d3.select(this)
                .attr('d', line)
                .attr('transform', null);

            // Animate Nodes
            d3.selectAll('.node-path').filter(filter).selectAll('.dot')
                .transition()
                .attr('transform', 'translate(-' + x(2) + ',0)')
                .duration(that.animationDelay)
                .ease(d3.easeLinear);
        }

        function tickCaller() {
            g.selectAll('.node-path').filter('.caller').selectAll('.dot').remove();

            tick.call(this, 'caller');

            d3.active(this)
                .attr('transform', 'translate(' + x(0) + ',0)')
                .transition()
                .duration(that.animationDelay)
                .ease(d3.easeLinear)
                .on('start', tickCaller);

        }

        function tickAgent() {
            g.selectAll('.node-path').filter('.agent').selectAll('.dot').remove();

            tick.call(this, 'agent');

            d3.active(this)
                .attr('transform', 'translate(' + x(0) + ',0)')
                .transition()
                .duration(that.animationDelay)
                .ease(d3.easeLinear)
                .on('start', tickAgent);

        }

        // gridlines in y axis function
        function make_y_gridlines() {
            return d3.axisLeft(y)
                .ticks(1);
        }

        // this.reDraw();

    }


}
